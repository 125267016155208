import React from 'react'
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div className='app__social-twitter'>
          <a href={'https://twitter.com/TrWiddi'} target={'_blank'} rel={'noreferrer noopener'}>
            <BsTwitter />
          </a>
        </div>
        <div className='app__social-facebook'>
          <a href={'https://www.facebook.com/guitarguytrevor/'} target={'_blank'} rel={'noreferrer noopener'}>
            <FaFacebookF />
          </a>
        </div>
        <div className='app__social-instagram'>
          <a href={'https://www.instagram.com/widdisontrevor/'} target={'_blank'} rel={'noreferrer noopener'}>
            <BsInstagram />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia